//import css Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
// Default CSS theme splide
import '@splidejs/splide/css';
//import yii css
//import "../css/_fonts.css";
import "../css/_variable.css";
import "../css/_glightbox.css";
import "../css/site.css";
import "../css/_header.css";
import "../css/_footer.css";
import "../css/_home.css";
import "../css/_aboutPage.css";
import "../css/_events.css";
import "../css/_teacher.css";
import "../css/_collezioni.css";
import "../css/_contact.css";

//import "../css/test.scss";

import "../css/_fullscreen.css";

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

import SplideGallery from '../js/splideGallery.js';

// Import gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import MenuToggler from '../js/menuToggler.js';
//import validation NslSubscription
import NslSubscription from '../js/nslSubscription.js';
//import animationhome
import AnimationHome from '../js/animationHome.js';
//import animationCollection
import AnimationCollection from '../js/animationCollection.js';
//import animationAboutPage
import AnimationAboutPage from '../js/animationAboutPage.js';
// import GLightbox
import LightBox from '../js/glightbox.js';

//import eventsFilter
import EventsFilter from '../js/eventsFilter.js';

console.log('[webpack bundle js]');

//MENU TOGGLER
MenuToggler.init();
//VALIDATION NSL SUBSCRIPTION
NslSubscription.init();
//GLIGHTBOX
LightBox.init();


// START GESTIONE SPLIDE GALLERY
const headerHome = document.querySelector('#headerHome');
const collectionHome = document.querySelector('#collectionHome');
const otherProducts = document.querySelector('#otherProducts');
const productDetail = document.querySelector('#productDetail');
const teacherDetail = document.querySelector('#teacherDetail');
if(collectionHome){ 
    SplideGallery.collectionHome(collectionHome);
}
if(headerHome){ 
    SplideGallery.headerHome(headerHome);
}
if(otherProducts){ 
    SplideGallery.otherProducts(otherProducts);
}
if(productDetail){ 
    SplideGallery.productDetail(productDetail);
}
if(teacherDetail){ 
    SplideGallery.productDetail(teacherDetail);
}
/* END GESTIONE SPLIDE */

//GSAP ANIMATION
gsap.registerPlugin(ScrollTrigger);

//initialize animation Home
const wrapperHome = document.querySelector('#wrapper-home');
if(wrapperHome){
  console.log('[ start animation wrapperHome]');
  AnimationHome.init();
}

const nav = gsap.timeline({
  scrollTrigger: {
      trigger: '.wrapper-menu',
      //pin: true,//'.container-main',
      scrub: 0.5,
      start: "top top",
      //end: "+=100%",
      markers:false,
  }
});
nav.to('.hideTop', {
  ease: "none",
  y: -200,
});

//ANIMAZIONE GSAP PAGINA COLLEZIONI
const collectionPage = document.querySelector('#collection-view');
if(collectionPage){
  console.log('[ start animation collectionPage]');
  AnimationCollection.init();
}

//ANIMAZIONE GSAP PAGINA AZIENDA
const aboutPage = document.querySelector('#about-page');
if(aboutPage){
  AnimationAboutPage.init();
}

//Gestione sottolineature
const elToHover = document.querySelectorAll('p em');
elToHover.forEach(em => { 
  em.addEventListener('mouseover', (el) => {
    em.classList.add('yellUnderlineOn')
    em.classList.remove('yellUnderlineOff')
  })
  em.addEventListener('mouseout', (el) => {
    em.classList.remove('yellUnderlineOn')
    em.classList.add('yellUnderlineOff')
  })
});

//Eventuali filtri pagina eventi
EventsFilter.init();

// Privacy Policy Iubenda
(function (w, d) {
  var loader = function () {
    var s = d.createElement("script"),
      tag = d.getElementsByTagName("script")[0];
    s.src = "https://cdn.iubenda.com/iubenda.js";
    tag.parentNode.insertBefore(s, tag);
  };
  if (w.addEventListener) {
    w.addEventListener("load", loader, false);
  } else if (w.attachEvent) {
    w.attachEvent("onload", loader);
  } else {
    w.onload = loader;
  }
})(window, document);

// Cookie Policy Iubenda
(function (w, d) {
  var loader = function () {
    var s = d.createElement("script"),
      tag = d.getElementsByTagName("script")[0];
    s.src = "https://cdn.iubenda.com/iubenda.js";
    tag.parentNode.insertBefore(s, tag);
  };
  if (w.addEventListener) {
    w.addEventListener("load", loader, false);
  } else if (w.attachEvent) {
    w.attachEvent("onload", loader);
  } else {
    w.onload = loader;
  }
})(window, document);

